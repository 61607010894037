.list {
  margin: 0;
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.popup {
  right: 0;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 20px;
  padding-bottom: 30px;
  border: none;
}
